

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.invite_contact_with_rtl {
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}
